<template>
    <div class="c-permissions">
        <v-title title="Permissions"></v-title>
        <title-box>
            <div class="row">
                <div class="col-9">
                    <h1>Permissions</h1>
                </div>
                <div class="col-3 text-right">
                    <mercur-button v-if="hasPermission('MerchantCentral/createPermissions')" :to="{ name: 'AddPermission' }" class="btn btn-primary text-uppercase">
                        <i class="fas fa-plus"></i> Add new permission
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listPermissions')">
                <div class="row mt-3">
                    <div class="col-6"><quick-search :quickSearch.sync="filters.search" /></div>
                    <div class="col-6"><pagination :pagination="pagination" @change="changeAmountOfItems" /></div>
                </div>
                <merchant-data-table class="shadow" :options="options" :quickSearch="filters.search" :url="url" ref="table" @paginationSet="updatePagination" :isNotApplicationBound="true"></merchant-data-table>
            </template>
            <p v-else>Not allowed to see this view</p>
        </div>

        <mercur-dialog :is-open.sync="dialog.isOpen">
            <div class="mt-3 mb-1">{{ dialog.content }}</div>
            <template #footer>
                <div class="text-right">
                    <button class="btn" @click="dialog.isOpen = false">Cancel</button>
                    <button class="btn" @click="revoke" :disabled="isLoading">Confirm</button>
                </div>
            </template>
        </mercur-dialog>

    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import QuickSearch from '@/components/elements/table/QuickSearch'
export default {
    name: 'PermissionsView',
    components: { MerchantDataTable, Pagination, QuickSearch },
    data () {
        return {
            url: CONFIG.API.ROUTES.PERMISSIONS.OVERVIEW,
            isDialogOpen: false,
            activeData: null,
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            dialog: {
                isOpen: false,
            },
            filters: {
                search: '',
            },
            options: {
                columns: {
                    'Permission Name': {
                        field: 'permissionName',
                        link: (value, data) => {
                            return {
                                name: 'EditPermission',
                                params: {
                                    permissionId: data.permissionId,
                                },
                            }
                        },
                        filterParams: {
                            filterOptions: ['contains'],
                            newRowsAction: 'keep',
                            clearButton: true,
                            suppressAndOrCondition: true,
                            textFormatter: (value) => {
                                return value.trim()
                            },
                        },
                    },
                    'Number of actions': { field: 'actions', filter: false, valueFormatter: (params) => params.data.actions.actionIds.length },
                    'Date Created': { field: 'dateCreated', filter: false },
                },
                quickSearchColumns: ['permissionName'],
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Edit',
                            to: ({ data }) => {
                                return {
                                    name: 'EditPermission',
                                    params: {
                                        permissionId: data.permissionId,
                                    },
                                }
                            },
                            isHidden: () => {
                                return !this.hasPermission('updatePermissions')
                            },
                        },
                        {
                            text: 'Revoke',
                            onClick: ({ data }) => {
                                this.triggerRevokeDialog(data)
                            },
                            isDisabled: () => {
                                return this.isLoading
                            },
                            isHidden: () => {
                                return !this.hasPermission('revokePermissions')
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
            },
        }
    },
    methods: {
        triggerRevokeDialog (data) {
            this.activeData = data
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to revoke this permission?`,
            }
        },
        revoke () {
            const url = CONFIG.API.ROUTES.PERMISSIONS.REVOKE
            this.isLoading = true
            this.post(url, { id: this.activeData.permissionId }, 'Revoke success').then(() => {
                this.$refs.table.refreshGrid()
                this.dialog = {
                    isOpen: false,
                }
            }).finally(() => {
                this.isLoading = false
            })
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
    },
}
</script>
